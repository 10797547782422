<template>
<div style="width:70%">
    <change-psd v-if="changePsd" @func="changeShow"></change-psd>
    <div class="basic" v-else>
        <h3>账号设置</h3>

        <div class="basicInfo">
            <ul>
               <li>
                    <div class="content-show-l">用户名</div>
                    <div class="content-show-r">{{userInfo.username}}
                        <!-- <a href="" @click="changePsd"></a> -->
                        <a href=""  @click.prevent.stop="changeName">修改用户名</a>
                        </div>

                </li>
                <li>
                    <div class="content-show-l">密码</div>
                    <div class="content-show-r">*******
                        <!-- <a href="" @click="changePsd"></a> -->
                        <a href=""  @click.prevent.stop="showPsd">修改密码</a>
                        </div>

                </li>
                <li>
                    <div class="content-show-l">手机号</div>
                    <div class="content-show-r">{{userInfo.tel}}</div>
                </li>
                <li>
                    <div class="content-show-l">性别</div>
                    <div class="content-show-r">
                        {{userInfo.sex}}
                         <a href="" @click.stop.prevent="changeSex">编辑性别</a>
                    </div>

                </li>
                <li>
                    <div class="content-show-l">出生日期</div>
                    <div class="content-show-r">
                       <p v-show="changeDate"> {{userInfo.birthday}}</p>
                        <div class="block">
                          <el-date-picker
                            v-model="value1"
                            type="date"
                            v-show="!changeDate"
                            placeholder="选择日期">
                          </el-date-picker>
                        </div>
                         <a href="" v-show="changeDate" @click.prevent.stop="changeDate = !changeDate">设置出生日期</a>
                        <div class="datePrimary" v-show="!changeDate">
                            <el-button type="primary" @click="updateBirth">确定</el-button>
                              <el-button type="info" plain @click="changeDate = !changeDate">取消</el-button>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="content-show-l">学校</div>
                   <div class="content-show-r">
                        {{userInfo.school}}
                         <a href="" @click.stop.prevent="changeSch">修改学校</a>
                    </div>
                </li>
                <li>
                    <div class="content-show-l">学院</div>
                   <div class="content-show-r">
                        {{userInfo.department}}
                         <a href="" @click.stop.prevent="changeDep">修改学院</a>
                    </div>
                </li>
                 <li>
                    <div class="content-show-l">学号</div>
                   <div class="content-show-r">
                       {{userInfo.stu_id}}
                         <a href="" @click.stop.prevent="changeStuId">修改学号</a>
                    </div>
                </li>
                <li>
                    <div class="content-show-l">个性签名</div>
                   <div class="content-show-r">
                       {{userInfo.motto}}
                         <a href="" @click.stop.prevent="changeMotto">修改个性签名</a>
                    </div>
                </li>

            </ul>
        </div>
    </div>

</div>
</template>

<script>
import changePsd from './set/changePsd.vue'
import { changeSch, changeStuId, changeMotto,changeSex, changeUserName,changeBirth,changeDep } from '../../API/User.js'
import { mapActions } from 'vuex'
export default {
  name: 'userSet',
  data () {
    return {
      value1: '',
      changePsd: false,
      changeDate: true,
      pickerOptions: {
        onPick () {
          console.log(this.value1)
        }
      }
    }
  },
  methods: {
    ...mapActions(['initUserInfo']),
    // isSex () {
    //   if (this.userInfo.sex === 0) {
    //     console.log(this.$router.query.changePsd)
    //     this.sex = '男'
    //   } else {
    //     this.sex = '女'
    //   }
    // },
    changeShow (data) {
      this.changePsd = data
    },
    showPsd () {
      this.changePsd = true
    },
    // 修改用户名
    changeName () {
      this.$prompt('请输入新的用户名', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: '格式不正确'
      }).then(async ({ value }) => {
        var form = { username: value }
        const { data: res } = await changeUserName(form)
        this.initUserInfo()
        // 更新获取用户信息
        this.$message({
          type: 'success',
          message: res.message
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        })
      })
    },
    // 修改出生日期
    async changeBirth () {
      var dateToFormat = this.value1 ? new Date(this.value1) : new Date('2000-01-01');

      var year = dateToFormat.getFullYear();
      var month = dateToFormat.getMonth() + 1;
      var day = dateToFormat.getDate();

      month = month.toString().padStart(2, '0');
      day = day.toString().padStart(2, '0');
      var form = { birthday: `${year}-${month}-${day}` }
      // console.log(form)
      const { data: res } = await changeBirth(form)

      this.initUserInfo()
      // 更新获取用户信息
      this.$message({
        type: 'success',
        message: res.message
      })
    },
    async updateBirth(){
      await this.changeBirth();
      this.changeDate = !this.changeDate; // 更新出生日期后，切换回展示模式
    },
    // 修改学校
    changeSch () {
      this.$prompt('请输入学校名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: '格式不正确'
      }).then(async ({ value }) => {
        var form = { school: value }
        const { data: res } = await changeSch(form)
        this.initUserInfo()
        // 更新获取用户信息
        this.$message({
          type: 'success',
          message: res.message
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        })
      })
    },
     // 修改学院
     changeDep () {
      this.$prompt('请输入学院名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputErrorMessage: '格式不正确'
      }).then(async ({ value }) => {
        var form = { department: value }
        const { data: res } = await changeDep(form)
        this.initUserInfo()
        // 更新获取用户信息
        this.$message({
          type: 'success',
          message: res.message
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        })
      })
    },
    // 修改性别
    changeSex () {
      this.$prompt('请输入您的性别 --- 男/女', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /['男'|'女']/,
        inputErrorMessage: '格式不正确'
      }).then(async ({ value }) => {
        var form = { sex: value }

        // console.log(form)
        const { data: res } = await changeSex(form)

        this.initUserInfo()
        // 更新获取用户信息
        this.$message({
          type: 'success',
          message: res.message
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        })
      })
    },
    // 修改学校
    changeStuId () {
      this.$prompt('请输入您的学号', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',

        inputErrorMessage: '格式不正确'
      }).then(async ({ value }) => {
        var form = { stuId: value }
        const { data: res } = await changeStuId(form)
        this.initUserInfo()
        this.$message({
          type: 'success',
          message: res.message
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        })
      })
    },
    // 修改个性签名
    changeMotto () {
      this.$prompt('请输入您的个性签名', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',

        inputErrorMessage: '格式不正确'
      }).then(async ({ value }) => {
        var form = { motto: value }
        const { data: res } = await changeMotto(form)
        this.initUserInfo()
        this.$message({
          type: 'success',
          message: res.message
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        })
      })
    }

  },
  components: {
    changePsd
  },
  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    userInfo: function () {
    //   console.log(this.$route.params)
      return this.$store.state.userInfo
    }
  }
}
</script>

<style lang="less" scoped>
html,body{
    background-color: #fff;
}
li{
    list-style: none;
}
a{
    text-decoration: none;
}
    .basic{
        background-color: #fff;
        h3{
            text-align: left;
            height: 48px;
            line-height: 48px;
            font-size: 18px;
            font-weight: 600;
            color: #2e2e2e;
            padding-left: 16px;
            margin:  0;

            border-bottom: 1px solid #f0f0f2
        }
        .basicInfo{
            ul{
                li{
                    line-height: 35px;
                    font-size: 13px;
                    padding-bottom: 13px;
                    display: flex;
                    border-bottom: 1px solid #f0f0f2;
                    margin-bottom: 20px;
                    padding-right: 45px;
                    align-items: center;
                    justify-content: space-between;
                    .content-show-l{
                        width: 56px;
                        color: #555666;
                        margin-right: 40px;
                        -ms-flex-negative: 0;
                        flex-shrink: 0;
                        padding-left: 8px;
                        display: -webkit-box;
                        display: -ms-flexbox;
                        display: flex;
                        -webkit-box-sizing: content-box;
                        box-sizing: content-box;
                        -webkit-box-pack: justify;
                        -ms-flex-pack: justify;
                        justify-content: space-between;

                    }
                    .content-show-r{
                            color: #222226;
                            word-break: break-all;
                            p{
                              margin: 0;
                              padding: 0;
                              display: inline-block;
                            }
                            a{
                            display: inline-block;
                            min-width: 56px;
                            // margin-left: 32px;
                            color: #1989fa;
                        }
                        .block{
                          display: inline-block;
                        }
                        .datePrimary{
                          display: inline-block;
                          padding-left:10px;
                        }
                    }
                    }
                }

            }
        }
</style>
