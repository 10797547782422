<template>
  <div>
    <div class="basic">
      <span @click="back">账号设置</span>
      <em>/</em>
      <span>修改密码</span>
       <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
       <el-form-item label="旧密码" prop="oldPsd">
          <el-input type="password" v-model="ruleForm.oldPsd" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPsd">
          <el-input type="password" v-model="ruleForm.newPsd" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="checkPass">
          <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item>

    <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>

    <el-button @click="resetForm('ruleForm')">重置</el-button>
  </el-form-item>
</el-form>
    </div>

  </div>
</template>
<script>
import { changePsd } from '../../../API/User.js'
export default {
  name: 'changePsd',
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入旧密码'))
      } else {
        callback()
      }
    }
    var validatePass1 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入新密码'))
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass')
        }
        if (this.ruleForm.oldPsd === this.ruleForm.newPsd) {
          callback(new Error('新旧密码重复'))
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.ruleForm.newPsd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      ruleForm: {
        oldPsd: '',
        newPsd: '',
        checkPass: ''
      },

      changePsd: false,
      rules: {
        oldPsd: [
          { validator: validatePass, trigger: 'blur' }
        ],
        newPsd: [
          { validator: validatePass1, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ]

      }
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        var form = { oldPsd: this.ruleForm.oldPsd, newPsd: this.ruleForm.newPsd }

        if (valid) {
          const { data: res } = await changePsd(form)
          if (res.status !== 0) {
            this.$message({
              showClose: true,
              message: res.message,
              type: 'error'
            })
            return false
          } else {
            setTimeout(() => {
              this.$emit('func', this.changePsd)
            }, 1500)
          }

          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          })
        } else {
          return false
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    back () {
      this.$emit('func', this.changePsd)
    }
  }
}
</script>
<style lang="less" scoped>
.el-form {
  width: 50%;
  margin: 0 auto;
}
 .basic{
        background-color: #fff;
        padding-bottom: .3333rem;
        span{
            display: inline-block;
            text-align: left;
            height: 48px;
            line-height: 48px;
            font-size: 12px;
            cursor: pointer;
            color: #999aaa;
            padding-left: .1333rem;
            margin: 10px 0;
        }
        span:hover{
              color:#ff3535
            }
        em{
          margin: .0833rem;
        }
      }

</style>
